/**
 * checks if any given key is in the list of selected values and setts selected accordingly
 * @param {Object} list - object formatted {key: {count, displayName}}
 * @param {String[]} selectedList - list of strings to check against
 * @return {{}}
 */
export default (list, selectedList) => {
  const returnObj = {};

  Object.entries(list).forEach(([key, { count, displayName }]) => {
    returnObj[key] = {
      count,
      selected: selectedList.includes(key),
      displayName,
    };
  });

  return returnObj;
};
