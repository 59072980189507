<script>
  import { afterUpdate, onMount } from 'svelte';
  import '@amedia/brick-button';

  import paginationSetup from '../lib/pagination/paginationSetup.js';
  import {
    currentPage,
    numOfPages as nfp,
    articleLimit,
    siteTheme,
    redusedPagination as resizePaginator,
  } from '../lib/store.js';

  let theme;
  let limit;
  let pageNum;
  let numOfPages;

  let paginator;
  // is used for redusing pagination btns
  let redusedPagination = false;

  const currentPageVersion = 'secondary';
  const otherPagesVersion = 'primary';

  onMount(() => {
    currentPage.subscribe((value) => (pageNum = value));
    nfp.subscribe((value) => (numOfPages = value));
    articleLimit.subscribe((value) => (limit = value));
    siteTheme.subscribe((value) => (theme = value));
    resizePaginator.subscribe((value) => (redusedPagination = value));

    paginator = document.getElementById('search-paginator');

    if (!paginator) {
      return;
    }

    paginationSetup(paginator);

    let nextBtn;
    let prevBtn;
    [
      ...document.getElementsByClassName('paginator-class-forward-or-next'),
    ].forEach((e) => {
      const text = e.lastElementChild?.textContent.toLowerCase();

      if (text === 'Neste') {
        nextBtn = e;
      } else if (text === 'Forrige') {
        prevBtn = e;
      }
    });

    window.addEventListener('resize', () => {
      paginator.style.setProperty(
        '--paginator-width',
        `${paginator.offsetWidth}px`
      );
      paginator.style.setProperty(
        '--previus-btn-heigth',
        `${prevBtn.offsetHeight}px`
      );
      paginator.style.setProperty(
        '--previus-btn-width',
        `${prevBtn.offsetWidth}px`
      );
      paginator.style.setProperty(
        '--next-btn-heigth',
        `${nextBtn.offsetHeight}px`
      );
      paginator.style.setProperty(
        '--next-btn-width',
        `${nextBtn.offsetWidth}px`
      );

      // is used for redusing pagination btns
      // redusedPagination = window.innerWidth < 600;
    });
  });

  afterUpdate(() => {
    paginator = document.getElementById('search-paginator');

    if (!paginator) {
      return;
    }
    paginationSetup(paginator);

    document.body.scrollIntoView(true);
  });
</script>

{#key pageNum}
  {#if numOfPages > 1}
    <nav aria-label="Pagienering søkeresultater." id="search-paginator">
      <ul>
        <!-- btn previous -->
        <li>
          <brick-button
            data-addClasses="{pageNum === 1
              ? 'disabled'
              : ''} paginator-class-forward-or-next"
            data-size="small"
            data-label="Forrige"
            data-disabled={pageNum === 1}
            data-version={otherPagesVersion}
            data-theme={theme}
            data-custom-on-click="movePage"
          />
        </li>

        <!-- listing of page btns -->
        {#if redusedPagination && numOfPages <= 7}
          {#each [...Array(numOfPages)] as _, page}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={page + 1}
                data-version={pageNum === page + 1
                  ? currentPageVersion
                  : otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/each}
        {:else if !redusedPagination && numOfPages <= 9}
          {#each [...Array(numOfPages)] as _, page}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={page + 1}
                data-version={pageNum === page + 1
                  ? currentPageVersion
                  : otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/each}
        {:else if (!redusedPagination && pageNum <= 4 && numOfPages > 9) || (redusedPagination && pageNum <= 4 && numOfPages > 7)}
          {#each [...Array(!redusedPagination ? 7 : 5)] as _, page}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={page + 1}
                data-version={pageNum === page + 1
                  ? currentPageVersion
                  : otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/each}
          <li aria-hidden="true">...</li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
        {:else if (!redusedPagination && pageNum >= numOfPages - 3) || (redusedPagination && pageNum >= numOfPages - 2)}
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={1}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li aria-hidden="true">...</li>
          <!-- remove button when the page is small -->
          {#if !redusedPagination}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={numOfPages - 6}
                data-version={otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={numOfPages - 5}
                data-version={otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/if}
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages - 4}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages - 3}
              data-version={pageNum === numOfPages - 3
                ? currentPageVersion
                : otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages - 2}
              data-version={pageNum === numOfPages - 2
                ? currentPageVersion
                : otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages - 1}
              data-version={pageNum === numOfPages - 1
                ? currentPageVersion
                : otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages}
              data-version={pageNum === numOfPages
                ? currentPageVersion
                : otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
        {:else}
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={1}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li aria-hidden="true">...</li>
          <!-- remove button when the page is small -->
          {#if !redusedPagination}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={pageNum - 2}
                data-version={otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/if}
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={pageNum - 1}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={pageNum}
              data-version={currentPageVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={pageNum + 1}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
          <!-- remove button when the page is small -->
          {#if !redusedPagination}
            <li>
              <brick-button
                data-size="small"
                data-addClasses="paginator-class-page-btn"
                data-label={pageNum + 2}
                data-version={otherPagesVersion}
                data-theme={theme}
                data-custom-on-click="moveToSpecificPage"
              />
            </li>
          {/if}
          <li aria-hidden="true">...</li>
          <li>
            <brick-button
              data-size="small"
              data-addClasses="paginator-class-page-btn"
              data-label={numOfPages}
              data-version={otherPagesVersion}
              data-theme={theme}
              data-custom-on-click="moveToSpecificPage"
            />
          </li>
        {/if}

        <!-- btn next-->
        <li>
          <brick-button
            data-size="small"
            data-addClasses="{pageNum === numOfPages
              ? 'disabled'
              : ''} paginator-class-forward-or-next"
            data-label="Neste"
            data-disabled={pageNum === numOfPages}
            data-version={otherPagesVersion}
            data-theme={theme}
            data-custom-on-click="movePage"
          />
        </li>
      </ul>
    </nav>
  {/if}
{/key}

<style>
  nav#search-paginator {
    --gap: 0.25rem;
    --paginator-width: 0px; /* gets set and updated in js */
    --previus-btn-heigth: 0px; /* get set and updated in js */
    --previus-btn-width: 0px; /* get set and updated in js */
    --next-btn-heigth: 0px; /* get set and updated in js */
    --next-btn-width: 0px; /* get set and updated in js */
    align-self: center;
    margin-top: auto;
    width: min-content;
  }

  ul {
    display: flex;
    gap: var(--gap);
    list-style: none;
    padding-inline-start: unset;
    position: relative;
  }

  @media screen and (max-width: 850px) {
    ul {
      padding-bottom: calc(
        ((var(--previus-btn-heigth) + var(--next-btn-heigth)) / 2) + var(--gap)
      );
    }

    li:first-child {
      position: absolute;
      top: calc((var(--gap) * 2) + var(--previus-btn-heigth));
      left: calc(
        (var(--paginator-width) / 2) -
          (var(--previus-btn-width) + (var(--gap) / 2))
      );
    }

    li:last-child {
      position: absolute;
      top: calc((var(--gap) * 2) + var(--next-btn-heigth));
      right: calc(
        (var(--paginator-width) / 2) -
          (var(--next-btn-width) + (var(--gap) / 2))
      );
    }
  }
</style>
