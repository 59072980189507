import { get } from 'svelte/store';

import {
  redusedPagination,
  currentPage as cp,
  offset,
  articleLimit,
} from '../store.js';
import urlUpdate from '../urlUpdate.js';

/**
 * takes the currentPage, removes one(added in afterUpdate), adds or removes num,
 * and multiples the resault by articleLimit.
 * the resulting in page number vi got in moved up or down by num.
 * the function then dispatches the result to the parent application
 * @param {number} num - a number to increment or decrement by
 */
const movePage = (num) => {
  offset.set((get(cp) - 1 + num) * get(articleLimit));
  const skipAmount = get(offset);
  urlUpdate({ offset: skipAmount !== 0 ? skipAmount : '' });
};

/**
 * takes in a currentPage, removes one(added in afterUpdate), and multiples the resault by articleLimit
 * the result is then something the same as the information taken in.
 * the function then dispatches this to the parent application
 * @param {number} num - the page number you want to move to
 */
const moveToSpecificPage = (num) => {
  offset.set((num - 1) * get(articleLimit));
  const skipAmount = get(offset);
  urlUpdate({ offset: skipAmount !== 0 ? skipAmount : '' });
};

/**
 * takes in page number and returns an object with the aria-label and if the page is current adds aria-current=page
 * @param {number} page - the page number for pagination
 * @returns {{"aria-current": string, "aria-label": string}|{"aria-label": string}}
 */
const ariaLabeling = (page) => {
  const currentPage = get(cp);

  return currentPage === page
    ? {
        'aria-label': `Gå til resultatside ${currentPage}.`,
        'aria-current': 'page',
      }
    : { 'aria-label': `Gå til resultatside ${page}.` };
};

/**
 * gets height and width from the paginator forward and next buttons and sett the values as css custom properties
 * @param {HTMLElement} paginator - the paginator element
 */
export default (paginator) => {
  paginator.style.setProperty(
    '--paginator-width',
    `${paginator.offsetWidth}px`
  );

  // is used for redusing pagination btns
  redusedPagination.set(window.innerWidth < 600);

  const paginatorBtns = document.querySelectorAll('#search-paginator button');
  paginatorBtns.forEach((e) => {
    // return if there is no data-custom-on-click on the brick-button
    if (!e.parentElement.hasAttribute('data-custom-on-click')) {
      return;
    }

    const text = e.lastElementChild?.textContent.toLowerCase();

    if (text === 'forrige') {
      paginator.style.setProperty(
        '--previus-btn-heigth',
        `${e.clientHeight}px`
      );
      paginator.style.setProperty('--previus-btn-width', `${e.offsetWidth}px`);
    }
    if (text === 'neste') {
      paginator.style.setProperty('--next-btn-heigth', `${e.clientHeight}px`);
      paginator.style.setProperty('--next-btn-width', `${e.offsetWidth}px`);
    }

    // set aria-labels: forward and back buttons
    if (e.classList.contains('paginator-class-forward-or-next')) {
      e.setAttribute('aria-label', `Gå til ${text} resultatside.`);
    }

    // set on click: forward and back buttons
    if (e.parentElement.dataCustomOnClick === 'movePage') {
      e.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation();
        movePage(text === 'forrige' ? -1 : +1);
      });
    }

    // set aria-labels: page-buttons
    if (e.classList.contains('paginator-class-page-btn')) {
      for (const [key, val] of Object.entries(ariaLabeling(parseInt(text)))) {
        e.setAttribute(key, val);
      }
    }

    // set on click: page-buttons
    if (e.parentElement.dataCustomOnClick === 'moveToSpecificPage') {
      e.addEventListener('click', () => moveToSpecificPage(parseInt(text)));
    }
  });
};
