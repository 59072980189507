<script>
  import { createEventDispatcher } from 'svelte';

  export let fieldLegend = '';
  export let radioGroupName = '';
  export let list;

  let random = `${Math.random()}`.slice(2);

  const dispatch = createEventDispatcher();

  /**
   * dispatches the value of selected event
   * @param {event} e - event object
   */
  const sendValue = (e) => {
    dispatch('change', e.target.value);
  };
</script>

{#if fieldLegend !== ''}
  <fieldset>
    <legend>{fieldLegend}</legend>
    {#each Object.entries(list) as [key, { displayName, count, selected }]}
      <brick-input
        data-type="radio"
        data-label-text="{displayName}{count > 0 ? ` (${count})` : ''}"
        data-name={radioGroupName !== ''
          ? radioGroupName
          : `radio-group-${random}`}
        data-value={key}
        data-checked={selected}
        on:change={sendValue}
      />
    {/each}
  </fieldset>
{:else}
  {#each Object.entries(list) as [key, { count, selected }]}
    <brick-input
      data-type="radio"
      data-label-text="{key}{count > 0 ? ` (${count})` : ''}"
      data-name={radioGroupName !== ''
        ? radioGroupName
        : `radio-group-${random}`}
      data-value={key}
      data-checked={selected}
      on:change={sendValue}
    />
  {/each}
{/if}

<style>
  brick-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 0.5rem;
  }
</style>
