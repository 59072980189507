<script>
  import '@amedia/brick-image';
  import '@amedia/brick-pill';
  //export let url;
  export let url;
  export let title;
  export let publishedDate;
  export let image;
  export let type;
  export let authorNames;

  $: showVideoPill = type === 'video';
  $: showGalleryPill = type === 'gallery';
  $: showOpinionPill = type === 'opinion';
</script>

<a class="searchCard{image ? ' has-image' : ''}" href={url}>
  <div class="text">
    <h2>{title}</h2>
    <div class="pill-container">
      <brick-pillbox data-filled="false" class="search-pills">
        <brick-pill
          data-text="Publisert: {publishedDate}"
          data-version="vignette"
        />
      </brick-pillbox>
      <brick-pillbox data-filled="false" class="search-pills">
        {#if showVideoPill}
          <brick-pill data-text="Video" data-version={type} />
        {/if}
        {#if showGalleryPill}
          <brick-pill data-text="Bildeserie" data-version={type} />
        {/if}
        {#if showOpinionPill}
          <brick-pill
            data-text="Debatt"
            data-version="vignette"
            style="overflow: visible;"
          />
        {/if}
        <brick-pill
          data-text={authorNames.map((a) => ` ${a}`)}
          data-version="vignette"
        />
      </brick-pillbox>
    </div>
  </div>
  {#if image}
    <div class="image">
      <brick-image-v3
        data-src={image.src}
        data-srcset={image.srcset}
        data-width={image.width}
        data-height={image.height}
      />
    </div>
  {/if}
</a>

<style>
  :root {
    --search-card-img-width: 133px;
    --search-card-img-height: 100px;
  }
  @media only screen and (max-width: 600px) {
    :root {
      --search-card-img-width: 100px;
      --search-card-img-height: 78px;
    }
  }

  .searchCard {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    justify-items: end;
    column-gap: var(--brick-space-x3);
    color: var(--brick-colors-baseFg);
    padding-block: var(--brick-space-x3, 1rem);
    border-top: 1px solid var(--brick-colors-utilitySecondaryDivider);
    cursor: pointer;
    text-decoration: none;
    text-decoration: underline;
    text-decoration-color: transparent;
  }
  .searchCard:hover h2 {
    text-decoration-color: var(--brick-colors-baseFg);
  }
  .searchCard.has-image {
    grid-template-columns: minmax(0, 1fr) var(--search-card-img-width);
  }

  h2 {
    padding: 0;
    margin: 0;
    font-family: var(--brick-fonts-baseSubheadM);
    font-size: var(--brick-fontSizes-baseSubheadM);
    font-weight: var(--brick-fontWeights-baseSubheadM);
    letter-spacing: var(--brick-letterSpacings-baseSubheadM);
    line-height: var(--brick-lineHeights-baseSubheadM);
    flex-basis: 100%;
    text-decoration: none;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s;
  }

  .image {
    width: var(--search-card-img-width);
    height: var(--search-card-img-height);
    height: 100%;
    object-fit: cover;
  }

  .text {
    --brick-fontWeights-pillBoldL: var(--brick-fontWeights-pillL);
    --brick-fontWeights-pillBoldM: var(--brick-fontWeights-pillM);
    padding: 0;
    margin: 0;
    display: flex;
    justify-self: self-start;
    flex-direction: column;
    gap: var(--brick-space-x3);
    max-width: 100%;
    min-height: var(--search-card-img-height);
  }
  .search-pills {
    min-height: 24px;
  }
  .pill-container {
    display: block;
    flex-grow: 0;
  }
</style>
