import { get } from 'svelte/store';

import isSelected from './isSelected.js';
import {
  ariaLiveMessage,
  articleLimit,
  articles,
  currentPage,
  dateCounts,
  journalists,
  numOfPages,
  selectedJournalists,
  selectedTags,
  selectedTypes,
  tags,
  types,
  publication,
  siteTheme,
  today,
  offset as of,
  loading,
} from './store.js';

let numArticles;

/**
 * gjør en fetch mot backend-en og oppdaterer div svelte stores
 * @param {String} urlInfo - string of search querys. Starting form &someParam=fisk&otherParam=salat
 * @return {Promise<void>}
 */
export default async (urlInfo) => {
  loading.set(true);
  const endpoint = window.hunter?.endpoint || '';
  const url = `${endpoint}/api/hunter/v1/query?publication=${get(
    publication
  )}&theme=${get(siteTheme)}&${decodeURIComponent(urlInfo)}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Got error ${response.status} from ${url}`);
  }

  const data = await response.json();

  articles.set(data.articles);
  tags.set(isSelected(data.tags, get(selectedTags)));
  journalists.set(isSelected(data.authors, get(selectedJournalists)));
  types.set(isSelected(data.types, get(selectedTypes)));
  dateCounts.set(data.dateCounts);
  today.set(new Date().toISOString());
  loading.set(false);

  numArticles = data.totalHits > 9989 ? 9989 : data.totalHits; // hack for acp-search bug when resolved set to numArticles = data.totalHits

  if (numArticles === 0) {
    ariaLiveMessage.set('Søket ga ingen resultater.');

    document
      .querySelector(
        'form#search-form fieldset:first-of-type input:first-of-type'
      )
      .focus();
  } else {
    let limit = get(articleLimit);
    let offset = get(of);

    let pageNum = offset / limit + 1;
    currentPage.set(pageNum);

    let pageCount = Math.ceil(numArticles / limit);
    numOfPages.set(pageCount);

    const articlesOnPage =
      numArticles - offset < 10 || numArticles < 10
        ? numArticles < 10
          ? numArticles
          : numArticles - offset
        : 10;
    ariaLiveMessage.set(
      `Du er nå på den første av ${articlesOnPage} artikler${
        pageCount > 1 ? ` på resultatside ${pageNum} av ${pageCount}` : ''
      }.`
    );
  }
};
