import Component from './search.svelte';

export default class Search extends HTMLElement {
  connectedCallback() {
    const { publication, theme } = this.dataset;
    this.publication = publication;
    this.theme = theme;
    const options = {
      target: this,
      props: {
        publication,
        theme,
      },
    };
    this.app = new Component(options);
  }
}

if (!customElements.get('amedia-search')) {
  customElements.define('amedia-search', Search);
}
