<script>
  import { createEventDispatcher } from 'svelte';

  export let fieldLegend;
  export let list;
  const dispatch = createEventDispatcher();

  /**
   * gets all inputs in this fieldset and dispatches a list of checked inputs
   */
  const select = () => {
    const checkedInputs = document.querySelectorAll(
      `.${CSS.escape(fieldLegend)} input`
    );
    let checkedList = [];

    checkedInputs.forEach((e) => {
      if (e.checked) {
        checkedList.push(e.value);
      }
    });

    dispatch('change', checkedList);
  };
</script>

<fieldset aria-label="Filter på {fieldLegend}.">
  <legend>{fieldLegend}</legend>
  {#each Object.entries(list) as [itemName, { count, selected }]}
    <brick-input
      class={fieldLegend}
      data-type="checkbox"
      data-class-list
      data-label-text="{itemName}{count > 0 ? ` (${count})` : ''}"
      data-name="{fieldLegend}-{itemName}"
      data-value={itemName}
      data-checked={selected}
      on:click={(e) => {
        if (e.target.name === `${fieldLegend}-${itemName}`) {
          select();
        }
      }}
      on:keydown={(e) => {
        if (e.keydown === 'Space') {
          select();
        }
      }}
    />
  {/each}
</fieldset>

<style>
  brick-input:nth-of-type(n + 5) {
    display: none;
  }

  brick-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 0.5rem;
  }
</style>
