<script>
  import '@amedia/brick-input';

  import { onMount } from 'svelte';
  import { get } from 'svelte/store';

  import {
    updateDateSelection,
    toggleDate,
  } from '../lib/filter-by-date/updateDateSelection.js';
  import getValue from '../lib/filter-by-date/getValue.js';
  import { dateCounts, dates, todaysDate } from '../lib/store.js';

  let dateFrom = '';
  let dateTo = '';

  /**
   * returns ISOString formatted date based current date plus or minus n days
   * @param {number} t - how meny days ago(- number) or to
   * @return {string} - date as ISOString
   */
  const dateValueSet = (t) => {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    return new Date(new Date() - -t * dayInMilliseconds).toISOString();
  };

  onMount(() => {
    dateCounts.subscribe((counts) => {
      const getLabel = (id) => {
        let element;
        document.getElementById(id).parentElement.childNodes.forEach((node) => {
          if (node.nodeName === 'LABEL') {
            element = node;
          }
        });
        return element;
      };

      ['all', 'day', 'week', 'month'].forEach((shortId) => {
        const label = getLabel(`date-filter-${shortId}`);

        let labelText;

        switch (shortId) {
          case 'all':
            labelText = `Alle (${counts[shortId]})`;
            break;
          case 'day':
            labelText = `Siste Døgn (${counts[shortId]})`;
            break;
          case 'week':
            labelText = `Siste Uke (${counts[shortId]})`;
            break;
          case 'month':
            labelText = `Siste 30 Dager (${counts[shortId]})`;
            break;
        }

        label.innerText = labelText;
      });
    });

    dates.subscribe((value) => {
      dateFrom = value.startDate.slice(0, 10);
      dateTo = value.endDate.slice(0, 10);

      updateDateSelection(dateTo !== '' ? dateTo : get(todaysDate), dateFrom);

      let radioChoiceArray = [
        document.getElementById('date-filter-day').value.slice(0, 10),
        document.getElementById('date-filter-week').value.slice(0, 10),
        document.getElementById('date-filter-month').value.slice(0, 10),
      ];

      if (
        !radioChoiceArray.includes(dateFrom) &&
        dateFrom !== '' &&
        (dateTo !== '' || dateTo !== get(todaysDate))
      ) {
        return;
      }

      switch (dateFrom) {
        case radioChoiceArray[0]:
          document.getElementById('date-filter-day').checked = true;
          break;
        case radioChoiceArray[1]:
          document.getElementById('date-filter-week').checked = true;
          break;
        case radioChoiceArray[2]:
          document.getElementById('date-filter-month').checked = true;
          break;
        default:
          document.getElementById('date-filter-all').checked = true;
      }
    });
  });
</script>

<fieldset aria-label="Filterer etter hvor nye sakene er.">
  <legend>Dato</legend>
  <brick-input
    data-type="radio"
    data-label-text="Alle"
    data-id="date-filter-all"
    data-name="date-selector"
    data-value="all"
    on:change={getValue}
  />
  <brick-input
    data-type="radio"
    data-label-text="Siste Døgn"
    data-id="date-filter-day"
    data-name="date-selector"
    data-value={dateValueSet(-1)}
    on:change={getValue}
  />
  <brick-input
    data-type="radio"
    data-label-text="Siste Uke"
    data-id="date-filter-week"
    data-name="date-selector"
    data-value={dateValueSet(-7)}
    on:change={getValue}
  />
  <brick-input
    data-type="radio"
    data-label-text="Siste 30 Dager"
    data-id="date-filter-month"
    data-name="date-selector"
    data-value={dateValueSet(-30)}
    on:change={getValue}
  />
  <brick-input
    data-type="radio"
    data-label-text="Egendefinert tidsrom."
    data-name="date-selector"
    data-id="SelfDefTime"
    on:change={toggleDate}
  />

  <div class="dateSelection" hidden>
    <brick-input
      data-label-text="fra: "
      data-id="dateFrom"
      data-type="date"
      data-max={get(todaysDate)}
      data-value={dateFrom}
      on:change={(e) =>
        (document.getElementById('dateTo').min = e.target.value)}
    />

    <brick-input
      data-label-text="til: "
      data-id="dateTo"
      data-type="date"
      data-min={dateFrom}
      data-max={get(todaysDate)}
      data-value={dateTo}
      on:change={(e) =>
        (document.getElementById('dateFrom').max = e.target.value)}
    />

    <brick-input
      data-type="button"
      data-value="Sett dato"
      data-id="userDefDate"
      on:click|preventDefault={getValue}
      on:keydown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          getValue(e);
        }
      }}
    />
  </div>
</fieldset>

<style>
  brick-input[data-type='radio'] {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 0.5rem;
  }

  .dateSelection[hidden] {
    display: none;
  }
</style>
