import onClickFunctions from './onClickFunctions.js';

/**
 * Takes the function name you have assigned to brick elements finds all elements with data-custom-on-click=[functionName]
 * gets the element and add an on click event for that function on each one
 * @param {string} functionName - name of function you want to sign to brick-buttons
 */
export default (functionName) => {
  const btns = document.querySelectorAll(
    `[data-custom-on-click=${CSS.escape(functionName)}] > button`
  );
  btns.forEach((btn) => {
    btn.addEventListener('click', onClickFunctions[functionName]);
  });
};
