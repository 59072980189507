import { writable, derived } from 'svelte/store';

// site config
export let publication = writable('www.avisnavn.no');
export let siteTheme = writable('alfa');

// filters
export let filterHidden = writable(true);
export let tags = writable({});
export let selectedTags = writable([]);
export let journalists = writable({});
export let selectedJournalists = writable([]);
export let types = writable({});
export let selectedTypes = writable(['all']);

// pagination
export let offset = writable(0);
export let articleLimit = writable(10);
export let numOfPages = writable(0);
export let currentPage = writable(0);
export let redusedPagination = writable(false);

// date selection
export let dates = writable({
  startDate: '',
  endDate: '',
});
export let dateCounts = writable({ all: 0, day: 0, week: 0, month: 0 });
export let today = writable(new Date().toISOString());
export let todaysDate = derived([today], ($today, set) =>
  set(`${$today}`.slice(0, 10))
);
export let keyword = writable('');
// fetch response
export let articles = writable([]);
export let ariaLiveMessage = writable('');
export let loading = writable(false);
