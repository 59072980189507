import { get } from 'svelte/store';

import { filterHidden } from './store.js';

/**
 * prevents default incase it is inside a form and sets filterHidden to the opposite
 * @param { HTMLElement } e - element that is activated
 */
const openFilterMenu = (e) => {
  e.preventDefault();
  filterHidden.set(!get(filterHidden));
};

export default {
  openFilterMenu,
};
