import { offset, keyword } from './store.js';
import fetchInfo from './fetchInfo.js';

/**
 * takes in an object and adds, removes, or updates the query params with each key and preforms calls fetchInfo on
 * resulting query
 * @param {{}} obj - object with query params you want to change {key: value} = ?key=value. if the value is an empty string then it will remove the query param
 */
export default (obj) => {
  const url = new URL(window.location);

  for (const [key, value] of Object.entries(obj)) {
    if (value !== '' && value !== 'all') {
      url.searchParams.set(key, value);
      keyword.set(value);
    }
    if (key === 'offset' && value === '') {
      offset.set(0);
    }
  }

  history.pushState(null, '', url.toString());

  const urlInfo = new URLSearchParams(url.search);
  fetchInfo(urlInfo.toString());
};
