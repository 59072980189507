<!--suppress ALL -->
<script>
  import { designNameToClassName } from '@amedia/brick-classnames';
  import '@amedia/brick-input';
  import '@amedia/brick-button';
  import { afterUpdate, onMount } from 'svelte';

  import onPageLoad from '../lib/onPageLoad.js';
  import urlUpdate from '../lib/urlUpdate.js';
  import settUpBrickOnClick from '../lib/settUpBrickOnClick.js';
  import {
    filterHidden,
    siteTheme,
    tags,
    selectedTags,
    types,
    selectedTypes,
    journalists,
    selectedJournalists,
    publication as publicationDomain,
  } from '../lib/store.js';

  import FilterByDate from './filter-by-date.svelte';
  import FilterWithCheckboxes from './filter-with-checkboxes.svelte';
  import FilterWithRadioButtons from './filter-with-radiobuttons.svelte';
  import PopulateCards from './populate-cards.svelte';
  import Pagination from './pagination.svelte';
  import AriaLive from './aria-live.svelte';

  export let publication;
  export let theme;
  let query = '';

  //store based variables
  let subjects = {};
  let authors = {};
  let storyType = {};
  let hideFilter;

  /**
   * toggle aria-expanded and hidden attributes on fieldset#search-form-filters
   */
  const setFilterHiddenState = () => {
    setTimeout(() => {
      const dropDownBox = document.getElementById('search-form-filters');
      dropDownBox.hidden = hideFilter;
      dropDownBox.ariaExpanded = !hideFilter;
    }, 500);
  };

  onMount(() => {
    publicationDomain.set(publication);
    onPageLoad();
    settUpBrickOnClick('openFilterMenu');
    filterHidden.subscribe((value) => {
      hideFilter = value;
      setFilterHiddenState();
    });
    tags.subscribe((value) => (subjects = value));
    journalists.subscribe((value) => (authors = value));
    types.subscribe((value) => (storyType = value));
    siteTheme.set(theme);
  });

  // add history functionality
  window.addEventListener('popstate', () => {
    onPageLoad();
    settUpBrickOnClick('openFilterMenu');
    filterHidden.subscribe((value) => {
      hideFilter = value;
      setFilterHiddenState();
    });
    tags.subscribe((value) => (subjects = value));
    journalists.subscribe((value) => (authors = value));
    types.subscribe((value) => (storyType = value));
    siteTheme.set(theme);
  });

  afterUpdate(() => {
    settUpBrickOnClick('openFilterMenu');
    return filterHidden.subscribe((value) => {
      hideFilter = value;
      setFilterHiddenState();
    });
  });
</script>

<div id="search-container" class="container {designNameToClassName(theme)}">
  <form
    id="search-form"
    class="filter"
    aria-label="Søk og filterer søket."
    role="search"
    on:submit|preventDefault={() =>
      urlUpdate({
        keyword: query.length !== 0 ? query : '',
        offset: '',
      })}
  >
    <fieldset class="search-text" aria-label="fritekst søk.">
      <legend class="visually-hidden">Søk med tekst.</legend>

      <brick-input
        on:change={({ target: { value } }) => (query = value.trim())}
        data-value={query}
        data-label-text="Søk i hele avisen."
        data-label-hidden="true"
        data-type="search"
        data-theme={theme}
        id="search-field"
      />
      <brick-input
        data-value="Søk"
        data-type="submit"
        data-version="primary"
        data-theme={theme}
      />
    </fieldset>

    <!--{#key hideFilter}
      <brick-button
        data-custom-on-click="openFilterMenu"
        data-label="filter"
        data-version="secondary"
        data-bgcolor="transparent"
        data-iconplacement="right"
        data-iconid={hideFilter ? 'toggleclosed' : 'toggleopen'}
        data-icontheme={theme}
        data-theme={theme}
      />
    {/key}*/-->

    <fieldset id="search-form-filters" hidden>
      <FilterByDate />

      {#key subjects}
        <FilterWithCheckboxes
          fieldLegend="Emner"
          list={subjects}
          on:change={({ detail }) => {
            const urlSubjects =
              [...detail].length > 0
                ? encodeURIComponent([...detail].join(','))
                : '';
            selectedTags.set(detail);
            urlUpdate({
              tags: urlSubjects,
              offset: '',
            });
          }}
        />
      {/key}

      {#key authors}
        <FilterWithCheckboxes
          fieldLegend="Journalist"
          list={authors}
          on:change={({ detail }) => {
            const urlAuthors =
              [...detail].length > 0
                ? encodeURIComponent([...detail].join(','))
                : '';
            selectedJournalists.set(detail);
            urlUpdate({
              author: urlAuthors,
              offset: '',
            });
          }}
        />
      {/key}

      {#key storyType}
        <FilterWithRadioButtons
          fieldLegend="Artikkeltype"
          list={storyType}
          on:change={({ detail }) => {
            selectedTypes.set(detail);
            urlUpdate({
              types: detail,
              offset: '',
            });
          }}
        />
      {/key}
    </fieldset>
  </form>

  <PopulateCards />

  <Pagination />

  <AriaLive />
</div>

<style>
  @import 'search.css';

  .container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: start;
    padding-block-start: 2rem;
    width: 100%;
    background-color: var(--brick-colors-baseBg);
    min-height: 100lvh;
    padding-inline: 2.5rem;
    padding-block: 1rem;
    box-sizing: border-box;
  }

  .container fieldset {
    margin: unset;
    padding: unset;
  }

  #search-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .search-text {
    grid-column: span 3;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1em;
    border: none;
  }

  #search-form-filters {
    grid-column: span 4;
    display: flex;
    width: 100%;
    padding-top: 1rem;
    backdrop-filter: brightness(98%);
    border: unset;
  }

  .search-text > brick-input[data-type='search'] {
    flex-grow: 2;
  }

  @media screen and (max-width: 850px) {
    .container {
      gap: 1rem;
      padding-inline: 0.5rem;
      padding-block: 0.75rem;
    }

    #search-form-filters {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  [hidden] {
    display: none !important;
  }

  @media screen and (max-width: 450px) {
    #search-form {
      margin-block-end: unset;
    }

    .search-text {
      grid-column: span 4;
    }

    #search-form-filters {
      display: flex;
      flex-direction: column;
      padding-inline: 1rem;
      box-sizing: border-box;
    }
  }
</style>
