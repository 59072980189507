import { get } from 'svelte/store';

import { todaysDate } from '../store.js';

/**
 * show or hides element with class .dateSelection if target elements id is not selfDefTime or parrent element classlist
 * contains 'dateSelection'
 * @param {event} target - target element in event object
 */
export const toggleDate = ({ target }) => {
  // as the selector is placed on the element inside the brick element vi have
  // to get the parent of its parent to see the element outside the brick element
  const parentElement = target?.parentElement.parentElement;
  const dateSelection = document.querySelector('.dateSelection');

  dateSelection.hidden = !(
    target.id === 'SelfDefTime' ||
    parentElement.classList.contains('dateSelection')
  );
};

/**
 * updates a date picker
 * @param {string} dateId - id of date picker to update
 * @param {string} dateVal - iso-string formatted date or empty string to date picker.
 */
export const updateDate = (dateId, dateVal) => {
  let datePicker = document.getElementById(dateId);

  if (dateVal.length !== get(todaysDate).length) {
    return;
  }

  datePicker.value = dateVal;
};

/**
 * show or hide dateSelection aria, and update date fields
 * @param {string} dateTo - iso-string formatted date.
 * @param {string} dateFrom - iso-string formatted date.
 */
export const updateDateSelection = (dateTo, dateFrom) => {
  const dateSelection = document.querySelector('.dateSelection');

  let isEmpty_DateTo = dateTo === '' || dateTo === undefined;
  let isToDayDateTo =
    !isEmpty_DateTo && dateTo.slice(0, 10) === get(todaysDate);

  let isEmpty_DateFrom = dateFrom === '' || dateFrom === undefined;
  let radioChoiceArray = [
    document.getElementById('date-filter-day').value.slice(0, 10),
    document.getElementById('date-filter-week').value.slice(0, 10),
    document.getElementById('date-filter-month').value.slice(0, 10),
  ];

  // check if NewDateTo is not an empty string, undefined, or today
  let shouldShow_NewDateTo = !isEmpty_DateTo && !isToDayDateTo;

  // check if NewDateFrom is not an empty string, undefined, or one of the radio button choices
  let shouldShow_NewDateFrom =
    !isEmpty_DateFrom && !radioChoiceArray.includes(dateFrom);

  if (!isEmpty_DateFrom) {
    updateDate('dateFrom', dateFrom);
  }

  if (!isEmpty_DateTo) {
    updateDate('dateTo', dateTo);
    document.getElementById('dateTo').min = dateFrom;
  }
  if (shouldShow_NewDateTo || shouldShow_NewDateFrom) {
    document.getElementById('SelfDefTime').checked = true;
    dateSelection.hidden = false;
  } else {
    dateSelection.hidden = true;
  }
};
