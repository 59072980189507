<script>
  import { keyword } from '../lib/store.js';
</script>

<div class="list-items">
  <div class="wrapper-message">
    <p class="title-message">
      Det var ingen treff
      {#if $keyword !== ''}
        på <span class="subtitle-message">{$keyword}</span>
      {/if}
    </p>
  </div>
</div>

<style>
  .wrapper-message {
    display: flex;
    justify-content: space-around;
    font-family: var(--brick-fonts-baseSubheadM);
    font-size: var(--brick-fontSizes-baseSubheadM);
    font-weight: var(--brick-fontWeights-baseSubheadM);
    letter-spacing: var(--brick-letterSpacings-baseSubheadM);
    line-height: var(--brick-lineHeights-baseSubheadM);
    color: var(--brick-colors-baseFg);
  }
  .subtitle-message {
    color: var(--brick-colors-baseSubtleFg);
  }
</style>
