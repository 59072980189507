import { get } from 'svelte/store';

import { dates, today, todaysDate } from '../store.js';
import urlUpdate from '../urlUpdate.js';

import { updateDate, toggleDate } from './updateDateSelection.js';

/**
 * gets the timestamps to filter dates on
 * @param {event} e - event object
 */
export default (e) => {
  let {
    target: { value: dateValue },
    target,
  } = e;

  toggleDate(e);
  if (!(dateValue instanceof Date)) {
    dateValue = new Date(dateValue) || '';
  }

  let startDate = '';
  let endDate = '';

  if (target.id === 'date-filter-all') {
    updateDate('dateTo', get(today));
    updateDate('dateFrom', '');
    document.getElementById('dateTo').min = '';
  } else if (target.id === 'userDefDate') {
    const dateTo = document.getElementById('dateTo').value;
    const dateFrom = document.getElementById('dateFrom').value;

    startDate = new Date(dateFrom).toISOString();
    endDate = new Date(dateTo).toISOString();
  } else {
    updateDate('dateTo', get(today));
    updateDate('dateFrom', dateValue.toISOString());

    document.getElementById('dateFrom').max = get(todaysDate);
    document.getElementById('dateTo').min = dateValue
      .toISOString()
      .slice(0, 10);

    startDate = dateValue.toISOString();
    endDate = get(today);
  }

  dates.set({
    endDate,
    startDate,
    offset: '',
  });
  urlUpdate(get(dates));
};
