<script>
  import { afterUpdate } from 'svelte';

  import { ariaLiveMessage } from '../lib/store.js';

  let ariaLiveOutput;

  afterUpdate(() => {
    return ariaLiveMessage.subscribe((value) => {
      if (!navigator.userAgent.includes('Chrome')) {
        setTimeout(() => {
          ariaLiveOutput = value;
        }, 100);
      } else {
        ariaLiveOutput = value;
      }
    });
  });
</script>

<span id="ariaLive" role="status" aria-live="assertive" aria-atomic="true">
  {ariaLiveOutput}
</span>

<style>
  #ariaLive {
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
</style>
