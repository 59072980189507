<script>
  import { loading, articles } from '../lib/store.js';

  import Loading from './loading.svelte';
  import CardList from './card-list.svelte';
</script>

{#if $loading}
  <div class="list-items">
    <Loading />
  </div>
{:else}
  <CardList articles={$articles} />
{/if}
