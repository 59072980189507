<script>
  //import { articles } from '../lib/store.js';

  import Card from './card.svelte';
  import Empty from './empty.svelte';
  export let articles;
</script>

{#if articles.length === 0}
  <Empty />
{:else}
  <section class="search-result-teasers" aria-labelledby="search-results-id">
    <h2 id="search-results-id" class="visually-hidden">Søkeresultater.</h2>
    {#each articles as data (data.id)}
      <Card
        publishedDate={data.publishedDate}
        url={data.url}
        title={data.title}
        image={data.image}
        type={data.type}
        authorNames={data.authorNames}
      />
    {/each}
  </section>
{/if}

<style>
  section {
    display: grid;
    grid-template-columns: 1fr;
  }
</style>
