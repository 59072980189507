<script>
  import { fade } from 'svelte/transition';
</script>

<div class="container" transition:fade>
  <div class="article">
    <div class="line" />
    <div class="line" />
    <div class="image" />
  </div>

  <div class="article">
    <div class="line" />
    <div class="line" />
    <div class="image" />
  </div>

  <div class="article">
    <div class="line" />
    <div class="line" />
    <div class="image" />
  </div>
</div>

<style>
  :root {
    --loading-card-img-width: 133px;
    --loading-card-img-height: 100px;
  }
  @media only screen and (max-width: 600px) {
    :root {
      --loading-card-img-width: 100px;
      --loading-card-img-height: 78px;
    }
  }

  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .article {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr var(--loading-card-img-width);
    align-items: center;
    /* justify-items: start; */
    padding: 10px;
    border-top: 1px solid var(--brick-colors-utilitySecondaryDivider);
    column-gap: var(--brick-space-x5);
  }
  .article .image {
    float: right;
    width: var(--loading-card-img-width);
    height: var(--loading-card-img-height);
    background-color: var(--brick-colors-utilitySecondarySubtleBg);
    border-radius: var(--brick-radii-baseM);
    margin: 8px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-image 1.6s infinite linear;
    grid-row-start: 1;
    grid-row-end: -1;
    grid-column-start: 2;
  }
  .article .line {
    float: left;
    width: auto;
    height: 16px;

    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines 1.6s infinite linear;
  }

  @media (prefers-reduced-motion) {
    /* styles to apply if a user's device settings are set to reduced motion */
    .article .line {
      animation: none;
    }

    .article .image {
      animation: none;
    }
  }
  .article .line + .line {
    width: 75px;
  }
  .article .line ~ .line {
    background-color: #ddd;
  }

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
  @keyframes shine-image {
    0% {
      background-position: -32px;
    }
    40%,
    100% {
      background-position: 208px;
    }
  }
</style>
