import fetchInfo from './fetchInfo.js';
import {
  dates,
  selectedTypes,
  selectedTags,
  selectedJournalists,
  filterHidden,
  offset,
} from './store.js';

/** Henter ut relevant query params i query url-en setter filter, søkefeldt, og pagination. Kaller der etter fetchInfo. */
export default () => {
  const loadedUrlQuery = new URLSearchParams(window.location.search);
  let filtered = false;
  let hadQuery = false;

  // todo: decide if we are using query or keyword
  if (loadedUrlQuery.has('query')) {
    loadedUrlQuery.set('keyword', loadedUrlQuery.get('query'));
    loadedUrlQuery.delete('query');
    hadQuery = true;
  }

  if (loadedUrlQuery.has('keyword')) {
    document.querySelector('#search-field > input').value =
      loadedUrlQuery.get('keyword');
  } else {
    document.querySelector('#search-field > input').value = '';
  }

  if (loadedUrlQuery.has('endDate') && loadedUrlQuery.has('startDate')) {
    filtered = true;
    dates.set({
      endDate: loadedUrlQuery.get('endDate'),
      startDate: loadedUrlQuery.get('startDate'),
    });
  } else if (loadedUrlQuery.has('startDate')) {
    filtered = true;
    dates.set({
      endDate: new Date().toISOString(),
      startDate: loadedUrlQuery.get('startDate'),
    });
  } else {
    dates.set({
      endDate: '',
      startDate: '',
    });
  }

  if (loadedUrlQuery.has('tags')) {
    filtered = true;
    selectedTags.set(decodeURIComponent(loadedUrlQuery.get('tags')).split(','));
  } else {
    selectedTags.set([]);
  }

  if (loadedUrlQuery.has('types')) {
    filtered = true;
    selectedTypes.set(loadedUrlQuery.get('types'));
  } else {
    selectedTypes.set(['all']);
  }

  if (loadedUrlQuery.has('author')) {
    filtered = true;
    selectedJournalists.set(
      decodeURIComponent(loadedUrlQuery.get('author')).split(',')
    );
  } else {
    selectedJournalists.set([]);
  }

  if (loadedUrlQuery.has('offset')) {
    offset.set(parseInt(loadedUrlQuery.get('offset'), 10));
  } else {
    offset.set(0);
  }

  if (filtered) {
    filterHidden.set(false);
  }

  if (hadQuery) {
    window.location.search = loadedUrlQuery.toString();
  }

  fetchInfo(loadedUrlQuery.toString());
};
